import type { Search, SearchSuggestion } from '@setplex/tria-api'
import { format as formatCollection } from '../collections/format'
import { format as formatContent } from '../content/format'
import { format as formatCreator } from '../creators/format'
import { format as formatChannel } from '../live/format'
import { format as formatTag } from '../tags/format'
import type { ApiSearch, ApiSearchSuggestion } from './index.h'

export const format = ({
  creators,
  contents,
  collections,
  channels,
  tags,
}: ApiSearch): Search => ({
  creators: creators && creators.length ? creators.map(formatCreator) : [],
  contents: contents && contents.length ? contents.map(formatContent) : [],
  collections:
    collections && collections.length ? collections.map(formatCollection) : [],
  channels: channels && channels.length ? channels.map(formatChannel) : [],
  tags: tags && tags.length ? tags.map(formatTag) : [],
})

export const formatSuggestion = ({
  creators,
  contents,
  collections,
  tags,
  channels,
}: ApiSearchSuggestion): SearchSuggestion => ({
  creators: creators && creators.length ? creators.map(formatCreator) : [],
  contents: contents && contents.length ? contents.map(formatContent) : [],
  collections:
    collections && collections.length ? collections.map(formatCollection) : [],
  tags: tags && tags.length ? tags.map(formatTag) : [],
  channels: channels && channels.length ? channels.map(formatChannel) : [],
})
