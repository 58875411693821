import type {
  EpgInfo,
  EpgInfoDto,
  LiveChannel,
  TimeRange,
  TvEpg,
  TvEpgApiDto,
} from '@setplex/tria-api'
import { MediaTypes } from '@setplex/tria-api'
import { ONE_MINUTE_MS } from '../../constants/time'
import type { ApiLiveChannel } from './index.h'

export const format = ({
  id,
  number,
  title,
  description,
  ageRating,
  logo,
  banner,
  channelEpgId,
  isFavorite,
}: ApiLiveChannel): LiveChannel => ({
  id,
  number,
  title,
  description,
  ageRating,
  logo,
  banner,
  channelEpgId,
  isFavorite,
  contentType: MediaTypes.LIVE_CHANNEL,
})

/**
 * Function truncates the seconds and adds zeros instead of seconds
 * 1724285519000 (in ISO '2024-08-22T00:11:59Z') will be 1724285460000 ('2024-08-22T00:11:00Z')
 * @param date
 */
export const setZeroSecondsMs = (date: Date | number): number => {
  const curDateNumber = typeof date === 'number' ? date : Number(date)
  const minutes = Math.trunc(curDateNumber / ONE_MINUTE_MS)
  return minutes * ONE_MINUTE_MS
}

/**
 * Function formats date f.e. '2024-08-22T00:00:00Z' to timestamp in milliseconds (1724284800000)
 * @param item
 * @param channelEpgId
 */
export const formatEPGProgram = (
  item: EpgInfoDto,
  channelEpgId: string
): EpgInfo => {
  const start = setZeroSecondsMs(+new Date(item.start))
  const stop = setZeroSecondsMs(+new Date(item.stop))

  return {
    id: `${channelEpgId}${start}${stop}`,
    channelEpgId,
    name: item.title,
    description: item?.description !== 'N/A' ? item.description : '',
    startMs: start,
    stopMs: stop,
    actualStartMs: start,
    actualStopMs: stop,
    durationMs: stop - start,
    actualDurationMs: stop - start,
    subtitle: item?.subtitle !== 'N/A' ? item.subtitle : undefined,
    categories: item.categories,
    icon: item?.icon !== 'N/A' ? item.icon : undefined,
    date: item?.date !== 'N/A' ? item.date : undefined,
    rating: item?.rating !== 'N/A' ? item.rating : undefined,
    directors: item.directors,
    actors: item.actors,
    title: item?.title,
    episode: item?.episode !== 'N/A' ? item.episode : undefined,
    isEmpty: item?.isEmpty || false,
  }
}

const generateEmptyProgram = (
  start: number,
  stop: number,
  channelEpgId: string
): EpgInfo => ({
  id: `${channelEpgId}${start}${stop}`,
  channelEpgId,
  name: undefined,
  description: undefined,
  isEmpty: true,
  startMs: start,
  stopMs: stop,
  actualStartMs: start,
  actualStopMs: stop,
  durationMs: stop - start,
  actualDurationMs: stop - start,
  categories: [],
  directors: [],
  actors: [],
})

const generateEmptyPrograms = ({
  start,
  end,
  channelEpgId,
}: {
  start: number
  end: number
  channelEpgId: string
}): EpgInfo => generateEmptyProgram(start, end, channelEpgId)

export const fillGaps = (programs: EpgInfo[], period: TimeRange): EpgInfo[] => {
  const ACCEPTABLE_GAP = 5 * ONE_MINUTE_MS // 5 minutes gap is acceptable

  // If no programs, return single empty program for whole period
  // if (programs.length === 0) {
  //   return [generateEmptyProgram(period.start, period.end, '')]
  // }

  const tryToGenerateGap = ({
    start,
    end,
    channelEpgId,
  }: {
    start: number
    end: number
    channelEpgId: string
  }): EpgInfo | undefined => {
    if (start >= end) return
    return generateEmptyProgram(start, end, channelEpgId)
  }

  return programs
    .sort((a, b) => a.startMs - b.startMs)
    .reduce((filled: EpgInfo[], item, index, originalArr) => {
      if (index === 0) {
        // Handle gap at the start
        const emptySlot = tryToGenerateGap({
          start: period.start,
          end: item.startMs,
          channelEpgId: item.channelEpgId,
        })
        if (emptySlot) filled.push(emptySlot)
        filled.push(item)
      } else {
        const prevProgram = filled[filled.length - 1]
        const gapDuration = item.startMs - prevProgram.stopMs

        if (gapDuration < ACCEPTABLE_GAP) {
          // If gap duration is smaller 5 minutes extend the previous program
          const extendedProgram = {
            ...prevProgram,
            stopMs: item.startMs,
            actualStopMs: item.startMs,
            durationMs: item.startMs - prevProgram.startMs,
            actualDurationMs: item.startMs - prevProgram.startMs,
          }
          filled[filled.length - 1] = extendedProgram
        } else {
          // or add gap
          const gap = tryToGenerateGap({
            start: prevProgram.stopMs,
            end: item.startMs,
            channelEpgId: item.channelEpgId,
          })
          if (gap) filled.push(gap)
        }
        filled.push(item)
      }

      // Handle gap at the end for the last program
      if (index === originalArr.length - 1) {
        const finalGapDuration = period.end - item.stopMs

        if (finalGapDuration >= ACCEPTABLE_GAP) {
          const endEmptySlot = tryToGenerateGap({
            start: item.stopMs,
            end: period.end,
            channelEpgId: item.channelEpgId,
          })
          if (endEmptySlot) {
            filled.push(endEmptySlot)
          }
        } else if (finalGapDuration > 0) {
          // Extend the last program if gap is small
          const extendedLastProgram = {
            ...item,
            stopMs: period.end,
            actualStopMs: period.end,
            durationMs: period.end - item.startMs,
            actualDurationMs: period.end - item.startMs,
          }
          filled[filled.length - 1] = extendedLastProgram
        }
      }

      return filled
    }, [])
}

const updateProgram = (
  program: EpgInfo,
  fields: {
    startMs?: number
    stopMs?: number
    actualStartMs?: number
    actualStopMs?: number
  }
) => {
  const newStart = fields.startMs || program.startMs
  const newStop = fields.stopMs || program.stopMs
  const newActualStartMs = fields.actualStartMs || program.actualStartMs
  const newActualStopMs = fields.actualStopMs || program.actualStopMs

  return {
    ...program,
    startMs: newStart,
    stopMs: newStop,
    durationMs: newStop - newStart,
    actualStartMs: newActualStartMs,
    actualStopMs: newActualStopMs,
    actualDurationMs: newActualStopMs - newActualStartMs,
  }
}

/**
 * Function cuts program`s start or stop value if it is bigger than start or end value of epg requested period
 * @param sortedEpgArray
 * @param period
 */
export const cutStartEndValues = (
  sortedEpgArray: EpgInfo[],
  period: TimeRange
): EpgInfo[] =>
  sortedEpgArray.map((program, index) => {
    if (index === 0) return updateProgram(program, { startMs: period.start })
    if (index === sortedEpgArray.length - 1)
      return updateProgram(program, { stopMs: period.end })
    return program
  })

const sortAndValidatePrograms = (
  programs: EpgInfo[],
  period: TimeRange
): EpgInfo[] => {
  const filledGaps = fillGaps(programs, period)
  const sorted = filledGaps.sort((a, b) => a.startMs - b.startMs)

  return cutStartEndValues(sorted, period)
}

export const formatEPGArray = (
  channelEpgId: string,
  channelProgramArr: EpgInfoDto[]
): EpgInfo[] =>
  channelProgramArr
    .filter((item) => item.title && item.description && item.start && item.stop)
    .map((item) => formatEPGProgram(item, channelEpgId))
    .filter((item) => item.startMs <= item.stopMs)

/**
 * Function adds 'empty' programs (gaps) if there is no epg for the period of time
 * @param channelEpgId
 * @param channelProgramArr
 * @param period
 */
const formatAndValidateEPGArray = (
  channelEpgId: string,
  channelProgramArr: EpgInfoDto[],
  period: TimeRange
): EpgInfo[] => {
  const formatted = formatEPGArray(channelEpgId, channelProgramArr)

  return sortAndValidatePrograms(formatted, period)
}

export const formatEPGPrograms = (
  programsCollection: TvEpgApiDto,
  channels: LiveChannel[] = [],
  period: TimeRange
): TvEpg => {
  const formattedProgramsAndGaps: TvEpg = {}

  Object.keys(programsCollection).forEach((epdId) => {
    const channel = channels.find(({ channelEpgId }) => channelEpgId === epdId)
    const existingPrograms = programsCollection[epdId]
    const isExistingToFormatting =
      existingPrograms?.length > 0 && Boolean(channel)

    formattedProgramsAndGaps[epdId] = isExistingToFormatting
      ? formatAndValidateEPGArray(epdId, existingPrograms, period)
      : []

    if (!formattedProgramsAndGaps[epdId].length) {
      formattedProgramsAndGaps[epdId] = [
        generateEmptyPrograms({
          start: period.start,
          end: period.end,
          channelEpgId: channel?.channelEpgId || '',
        }),
      ]
    }
  })
  return formattedProgramsAndGaps
}
