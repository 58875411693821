import { addDocumentTitle, addMetaDescription } from '@/helpers'
import { createEffect, type Effect } from 'effector'

/**
 * Used to navigate from old url, for example, /creator/117 to /creator/creator-name-117,
 * will be deleted when users stop using the old url
 */
export const makeSeoFriendlyUrlFx: Effect<string, void> = createEffect(
  (url: string) => window.history.replaceState({}, '', url)
)

export const setSeoInfoFx = createEffect(
  ({ title, description }: { title: string; description: string }) => {
    addDocumentTitle(title)
    addMetaDescription(description)
  }
)
