import type { API, GenericApiResponse, TvEpgApiDto } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { logger } from '../../utils/logger'
import { format, formatEPGPrograms } from './format'
import type { ApiLiveChannel, ApiLiveChannelCategory } from './index.h'

const epgToDateStringFormat = (str: number) =>
  new Date(str).toISOString().replace(/\.\d{3}Z$/, 'Z')

export function use(
  http: HttpClient,
  live: API['live'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // GET /api/web/live-channels/${id}
  live.base.getOneFx.use(async ({ id }) => {
    const json = await http.get<GenericApiResponse<ApiLiveChannel>>(
      `/live-channels/${id}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getOneFx')
    }

    return format(json.payload)
  })

  // GET api/web/live-channels/{id}/url
  live.base.getOnePlaybackUrlFx.use(async ({ id, headers = {} }) => {
    const json = await http.get<GenericApiResponse<{ playbackUrl: string }>>(
      `/live-channels/${id}/url`,
      {
        headers,
      }
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getOnePlaybackUrlFx')
    }

    return json.payload
  })

  // GET /api/web/live-channels
  live.base.getLiveChannelsFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getLiveChannelsFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  /* This query returns a list of channels sorted by number, which is used in the Player (for example, to switch channels) */
  // GET /api/web/live-channels/list
  live.base.getLiveChannelsListFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels/list${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getLiveChannelsListFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/live-channels/categories
  live.base.getLiveChannelsCategoriesFx.use(
    async ({ withChannelIds = 1, limit, offset }) => {
      const params = query({
        withChannelIds,
        limit,
        offset,
      })

      const json = await http.get<
        GenericApiResponse<{ results: ApiLiveChannelCategory[]; total: number }>
      >(`/live-channels/categories${params}`)

      if (!json || !json.payload) {
        throw new Error('Empty answer in live.base.getLiveChannelsCategoriesFx')
      }

      const results = json.payload.results || []

      return Object.defineProperty(results, 'total', {
        value: json.payload.total ?? 0,
        writable: false,
      })
    }
  )

  // GET /api/web/live-channels/by-category/${id}
  live.base.getLiveChannelsByCategoryFx.use(async ({ id, limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels/by-category/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getLiveChannelsByCategoryFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/live-channels/by-tag/${id}
  live.base.getLiveChannelsByTagFx.use(async ({ id, limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels/by-tag/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getLiveChannelsByTagFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/live-channels/by-collection/${id}
  live.base.getLiveChannelsByCollectionFx.use(async ({ id, limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels/by-collection/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getLiveChannelsByCollectionFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/live-channels/my-list
  live.base.getFavoritesFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiLiveChannel[]; total: number }>
    >(`/live-channels/my-list${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getFavoritesFx')
    }

    const results = (json.payload.results || []).map(format)
    // Total field is used to show favorites quantity at the NavBar label
    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // POST /api/web/live-channels/my-list/:id/add
  live.base.addToFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/live-channels/my-list/${id}/add`
    )
    if (!json) {
      throw new Error('Empty answer in live.base.addToFavoritesFx')
    }
  })

  // POST /api/web/live-channels/my-list/:id/remove
  live.base.removeFromFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/live-channels/my-list/${id}/remove`
    )
    if (!json) {
      throw new Error('Empty answer in live.base.removeFromFavoritesFx')
    }
  })

  // POST /api/web/live-channels/programmes
  live.base.getEpgInformationFormattedFx.use(
    async ({ epgIds, channels, period }) => {
      const body: {
        channelEpgIds: string[]
        fromDate: string
        toDate: string
      } = {
        channelEpgIds: epgIds,
        // format to string BE could handle
        fromDate: new Date(period.start)
          .toISOString()
          .replace(/\.\d{3}Z$/, 'Z'),
        toDate: epgToDateStringFormat(period.end),
      }

      if (!epgIds?.length) return { epg: {}, period } // no sense request // b.e. returns empty obj in that case

      let json
      try {
        json = await http.post<TvEpgApiDto>('/live-channels/programmes', {
          json: body,
        })
      } catch (apiCallError) {
        logger.error('getEpgInformationFormattedFx error ', apiCallError)
      }
      if (!json) {
        throw new Error('Empty answer in getEpgInformationFormattedFx')
      }

      return {
        epg: formatEPGPrograms(json, channels, period),
        period,
      }
    }
  )

  // GET /api/web/live-categories/{id}
  live.base.getOneLiveCategoryFx.use(async ({ id }) => {
    const json = await http.get<GenericApiResponse<ApiLiveChannelCategory>>(
      `/live-categories/${id}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in liveCategories.base.getOneFx')
    }

    return json.payload
  })
}
