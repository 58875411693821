import { createEvent, createStore, sample } from 'effector'
import { model as clock } from '~/shared/clock'
import { EPG_WEEK_DURATION, type EpgDateEx } from './index.h'
import { generateWeek, getToday, isSameDate } from './lib'

export const init = createEvent()

// today
export const updateToday = createEvent()

export const $today = createStore<EpgDateEx>(getToday()) //
  .on(updateToday, (current) => {
    const today = getToday()
    return isSameDate(current)(today) ? current : today
  })

// week from today to future, including today and yesterday
export const $weekWithYesterday = $today.map((today) =>
  generateWeek({ from: today, withYesterday: true })
)

// week from today to future
export const $week = $today.map((today) =>
  generateWeek({ from: today, duration: EPG_WEEK_DURATION })
)

sample({
  clock: [init, clock.$days],
  target: updateToday,
})
