export const INVALID_DATE: EpgDateEx = {
  year: NaN,
  month: NaN,
  day: NaN,
  isToday: false,
  isYesterday: false,
  isTomorrow: false,
}

export type EpgDate = {
  year: number
  month: number
  day: number
}

export type EpgDateEx = EpgDate & {
  isToday: boolean
  isYesterday: boolean
  isTomorrow: boolean
  title?: string
}

export const DEFAULT_WEEK_DURATION = 8
export const EPG_WEEK_DURATION = 3

/**
 * type to cover epg date string serialized form, like `2021-01-01` or `2021-1-1` (without leading zeros)
 *
 * while technically it is possible to implement more complex type, like this:
 * ```typescript
 * type D = `1` | `2` | `3` | `4` | `5` | `6` | `7` | `8` | `9`
 * type Z = `0` | `${D}`
 * type Year = `20${Z}${Z}`
 * type Month = `${D}` | `0${D}` | `10` | `11` | `12`
 * type Day = `${D}` | `0${D}` | `1${Z}` | `2${Z}` | `30` | `31`
 * export type EpgDateString = `${Year}-${Month}-${Day}`
 * ```
 * there are several gotchas:
 * 1. TypeScript has hardcoded limit of 100000 types in unions, so we can't add `19${Z}${Z}` for 1900-1999 years,
 *    otherwise typescript will throw an error "Expression produces a union type that is too complex to represent".
 *    union size in example above is 100 (years) * 12+10 (months) * 31+10 (days) = 100 * 22 * 41 = 90200
 * 2. TypeScript language server (or VSCode extension, which runs it) become very slow, CPU usage is constantly 100%,
 *
 * so, I use simple type for now, even while it is not 100% correct
 * if TypeScript will handle RegExp types someday, we can rewrite this
 * @see https://github.com/microsoft/TypeScript/issues/41160
 */
export type EpgDateString = `${number}-${number}-${number}`
