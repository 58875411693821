import { A, F } from '@mobily/ts-belt'
import { type TimeRange } from '@setplex/tria-api'
import { addDays, startOfDay, subDays } from 'date-fns'
import { Day, State, type Range, type RangePosition } from '../index.h'

/**
 * Check if any value is Range (structurly valid)
 */
export const isRange = (x: unknown): x is Range =>
  Array.isArray(x) &&
  x.length === 2 &&
  typeof x[0] === 'number' &&
  typeof x[1] === 'number'

/**
 * Check if range exists and is semantically valid (start > 0 && end > start)
 */
export const isValidRange = (range?: Range | null): range is Range =>
  isRange(range) && range[0] >= 0 && range[1] > range[0]

/**
 * Convert Range or TimeRange to Range
 */
export const toRange = (range: Range | Readonly<TimeRange>): Range =>
  isRange(range) ? range : [range.start, range.end]

/**
 * Checks if given time moment is inside of given range
 */
export const inRange: {
  (moment: number): (range: Range) => boolean
  (range: Range): (moment: number) => boolean
} = (momentOrRange: number | Range) => (rangeOrMoment: Range | number) => {
  const moment =
    typeof momentOrRange === 'number'
      ? (momentOrRange as number)
      : (rangeOrMoment as number)

  const range =
    typeof momentOrRange === 'number'
      ? (rangeOrMoment as Range)
      : (momentOrRange as Range)

  return range[0] <= moment && moment < range[1]
}

/**
 * Compare equality of two ranges
 */
export const isSameRange =
  (range1: Range | Readonly<TimeRange>) =>
  (range2: Range | Readonly<TimeRange>) => {
    const [a, b] = toRange(range1)
    const [x, y] = toRange(range2)
    return a === x && b === y
  }

/**
 * Calculate unloaded ranges from already loaded and requested one.
 * This function will find minimal set of ranges to load, to avoid useless requests for already loaded data.
 *
 * For example, if we have two loaded ranges `[June 1; June 10]` and `[June 20; June 30]`,
 * and requested range is `[June 5; June 25]`,
 * this function return `[June 10; June 20]` as minimal required set of ranges to load.
 *
 * Another example, if we have one loaded range `[June 10; June 15]`,
 * and requested range is `[June 5; June 25]`,
 * this function return two ranges `[June 5; June 10]` and `[June 15; June 25]`.
 *
 * All ranges are in UTC milliseconds, of course, this is just for example.
 */
export const unloadedRanges = (
  loaded: readonly Range[],
  requested?: Range | null
): readonly Range[] => {
  if (!isValidRange(requested)) return []
  const result: Range[] = [[...requested]]
  for (const [a, b] of loaded) {
    for (let i = 0; i < result.length; i++) {
      const [x, y] = result[i]

      const ends = [a, b, x, y].sort((a, b) => a - b)

      // requested range is fully loaded already
      // [++++(++++)++]
      // a    x    y  b
      if (F.equals(ends, [a, x, y, b])) {
        result.splice(i, 1)
        i--
      }

      // requested range is partially loaded already
      // [++++(++]----)
      // a    x  b    y
      else if (F.equals(ends, [a, x, b, y])) {
        result.splice(i, 1, [b, y])
      }

      // requested range is partially loaded already
      // (----[++)++++]
      // x    a  y    b
      else if (F.equals(ends, [x, a, y, b])) {
        result.splice(i, 1, [x, a])
      }

      // requested range is splitted by loaded range in two ranges
      // (----[+++]---)
      // x    a  b    y
      else if (F.equals(ends, [x, a, b, y])) {
        result.splice(i, 1, [x, a], [b, y])
        i++
      }
    }
  }
  return A.filter(result, isValidRange)
}

/**
 * Given a list of ranges, find biggest range that covers all of them.
 * This is needed to make single request for multiple channels. This will make some ranges redundant,
 * but it's better than making multiple requests.
 *
 * For example, if we have ranges `[June 1; June 10]` and `[June 20; June 30]`,
 * this function will return `[June 1; June 30]`.
 */
export const biggestCoverRange = (ranges: readonly Range[]): Range | null => {
  if (ranges.length === 0) return null
  let [a, b]: Range = [...ranges[0]]
  for (const [x, y] of ranges) {
    if (x < a) {
      a = x
    }
    if (y > b) {
      b = y
    }
  }
  return isValidRange([a, b]) ? [a, b] : null
}

/**
 * This function will fuse neighbouring ranges, to make less calculations in future.
 *
 * For example, if we have loaded ranges `[June 1; June 10]` and `[June 10; June 20]`,
 * this function will return single range `[June 1; June 20]`.
 */
export const fuseRanges = (ranges: readonly Range[]): Range[] => {
  if (ranges.length === 0) return []
  const result: Range[] = []

  ranges = ranges.slice().sort((a, b) => a[0] - b[0])
  let [a, b] = ranges[0]

  // merge same borders as well as with difference 1 between them
  // [a, b] [b, c] -> [a, c]
  // [a, b] [b + 1, c] -> [a, c]

  for (const [x, y] of ranges) {
    // separate ranges
    if (x > b + 1) {
      result.push([a, b])
      a = x
      b = y
    }

    // merge ranges
    else {
      if (y > b) {
        b = y
      }
    }
  }

  result.push([a, b])
  return result
}

/**
 * Get range position relative to exact moment in time (now)
 */
export const rangePosition = (
  [start, stop]: Range,
  now: number
): RangePosition => {
  const today = startOfDay(now).getTime()
  const yesterday = subDays(today, 1).getTime()
  const tomorrow = addDays(today, 1).getTime()
  const overmorrow = addDays(tomorrow, 1).getTime()

  // I think nested ternaries here is more readable than if-else-if-else-...
  /* eslint-disable no-nested-ternary */

  // prettier-ignore
  const dayOf = (moment: number) =>
    moment < yesterday  ? Day.Ereyesterday
  : moment < today      ? Day.Yesterday
  : moment < tomorrow   ? Day.Today
  : moment < overmorrow ? Day.Tomorrow
                        : Day.Overmorrow

  // prettier-ignore
  const state = stop <= now ? State.Elapsed
              : start > now ? State.Upcoming
                            : State.Ongoing

  /* eslint-enable no-nested-ternary */

  return {
    state,
    starts: dayOf(start),
    ends: dayOf(stop),
  }
}

/**
 * Checks if given big range is fully covered by other small ranges.
 * This function is needed to check, if we loaded all programs for selected range,
 * or there are uncovered gaps yet to fill.
 */
export const rangeCovered = (range: Range) => (by: readonly Range[]) => {
  const uncovered = unloadedRanges(by, range)
  return uncovered.length === 0
}
