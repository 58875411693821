export const OPEN_FROM = {
  BANNERS: 'Banners',
  HOME: 'Home',
  BROWSE: 'Browse',
  RECOMMENDED: 'Recommended',
  FAVORITES: 'Favorites',
  CREATOR: 'Creator page',
  SEARCH: 'Search results',
  SEARCH_CREATOR_LATEST_CONTENT: 'Search creators latest content',
  DIRECT_LINK: 'Direct link',
  LIVE: 'Live',
  MOVIES: 'Movies',
  LANGUAGES: 'Languages',
}

export const PLAYBACK_TRIGGER = {
  playerOpen: 'player_open',
  playButton: 'play_button',
  nextChannel: 'next_channel',
  prevChannel: 'previous_channel',
  selectChannelFromList: 'channel_list',
}
