import { dom, model as router, type History, type Route } from '!/router'
import { noop } from '@/helpers'
import { createEvent, sample, type EventCallable } from 'effector'
import { session } from '~/features/session'
import { analytics, launch, navigation } from '~/processes'

// init application
export const init: EventCallable<{
  history: History
  container: HTMLElement
  routes: Route
}> = createEvent()

// init router model at application init
sample({
  clock: init,
  fn: ({ history, routes }) => ({ history, routes }),
  target: router.init,
})

// init dom model at application init
sample({
  clock: init,
  fn: ({ container }) => ({ container }),
  target: dom.init,
})

// init application navigation process
sample({
  clock: init,
  fn: noop,
  target: navigation.init,
})

// init application launch process
sample({
  clock: init,
  fn: noop,
  target: launch.init,
})

// init application session process
sample({
  clock: init,
  fn: noop,
  target: session.init,
})

// init analytics
sample({
  clock: init,
  target: analytics.init,
})

//
// handlers for external usages
//

export const onLocationChange = navigation.renderFx.use
