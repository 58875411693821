import { A, F } from '@mobily/ts-belt'
import { isArray } from './is'
import { equalToBy, id, sortOrder } from './object'

/**
 * Convert any value to array,
 * and if it as already an array - leave it as it is
 */
export const toArray = <T>(value: T | T[]): T[] =>
  isArray(value) ? value : [value]

/**
 * Merge two arrays into new one, by given key getter.
 * New values will be appended to the end of the array,
 * and if there is already an item with the same key - it will be replaced
 */
export const concatBy =
  (by: (item: any) => any = F.identity) =>
  <T>(arr1?: T[]) =>
  (arr2?: T[]): T[] => {
    if (arr1 == null || A.isEmpty(arr1)) return arr2 ?? []
    if (arr2 == null || A.isEmpty(arr2)) return arr1 ?? []

    const result: T[] = arr1.slice()
    const equals = equalToBy(by)

    for (const item of arr2) {
      const idx = result.findIndex(equals(item))
      if (idx === -1) {
        result.push(item)
      } else {
        result[idx] = item
      }
    }

    return result
  }

/**
 * Merge arrays into new one, by `id` getter
 */
export const concatById = concatBy(id)

/**
 * Sort array by `id` field getter
 */
export const sortById = A.sortBy(id)

/**
 * Sort array by `sortOrder` field getter
 */
export const sortBySortOrder = A.sortBy(sortOrder)
