import { concatById, sortById, T } from '@/helpers'
import { type EpgInfo } from '@setplex/tria-api'
import { createEvent, createStore, sample } from 'effector'
import { type EpgRequestParams } from '../index.h'
import { narrowEpgRequestToNecessary } from '../lib/epg'
import { isValidRange } from '../lib/ranges'
import { fetchEpgInfo, fetchEpgInfoFx } from './fetch'
import { $loadedEpgRanges } from './ranges'

// whole epg matrix (array of Maps), by epg id
export const $epg = createStore<Map<string, EpgInfo[]>[]>([new Map()])

// fetch epg data request
export const fetchEpg = createEvent<EpgRequestParams>()

// for requested range, calculate biggest common unloaded range for all channels
sample({
  clock: fetchEpg,
  source: $loadedEpgRanges,
  filter: (_, requested) =>
    requested.channels.length > 0 && isValidRange(requested.period),
  fn: ([loadedRanges], requested) =>
    narrowEpgRequestToNecessary({ requested, loadedRanges }),
  target: fetchEpgInfo,
})

// fill epg matrix with loaded data
sample({
  clock: fetchEpgInfoFx.done,
  source: $epg,
  fn: ([programs], { params: { epgIds }, result: { epg } }) => {
    for (const id of epgIds) {
      const loaded = programs.get(id)
      const received = epg[id]
      const merged = sortById(concatById(loaded)(received)) as EpgInfo[]
      programs.set(id, merged)
    }
    return [programs]
  },
  target: $epg,
})

export const $epgIsReady = createStore(false)

sample({
  clock: fetchEpgInfoFx.done,
  fn: T,
  target: $epgIsReady,
})
